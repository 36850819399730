import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide14/image/imgPortadaGuia14.png"
import img1 from "@components/pageGuide/guides/guide14/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide14/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide14/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide14/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      10 indicadores de cobranzas de call center
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      Descubre qué métricas son esenciales para evaluar y optimizar tus
      operaciones de cobranzas. Además, conoce cómo puedes aprovecharlos para
      tomar decisiones informadas y potenciar tus resultados.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    Esta guía te ayudará a evaluar y mejorar el rendimiento de tus cobranzas
    mediante la interpretación de indicadores claves que te ayudan a conocer un
    panorama claro de cómo se encuentra tu empresa en la gestión de este tipo de
    campaña.
    <br /> <br />
    Desde el tiempo de antigüedad de las cuentas por cobrar hasta el porcentaje
    de llamada con promesas de pago, esta guía tiene como objetivo crea un
    criterio de mejora en cada aspecto crucial de tus actividades de cobranza.
  </p>
)

const data = {
  start: {
    support: "Guía | Cobranzas",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Tasa de rotación de cuentas.",
      },
      {
        key: 2,
        text: "Promedio de días de morosidad.",
      },
      {
        key: 3,
        text: "Deudas incobrables sobre ventas.",
      },
      {
        key: 4,
        text: "Facturación total vs cobranzas totales.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
